.support {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.supportFormContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: var(--white);
}

.contactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--white);
  padding-top: 2em;
  padding-bottom: 4em;
}
.contactUsChannels {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1em;
  box-sizing: border-box;
  padding: 4em 1em 4em 1em;
}
.contactUsChannels label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  width: 100%;
  height: 100%;
}
.addressbookLabelText {
  min-width: 90px;
  font-size: 1.2em;
  display: flex;
  justify-content: start;
  font-weight: 600;
}
.addressbookValue {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.supportForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 1em;
  padding: 1em;
  box-sizing: border-box;
}
.supportForm label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  width: 100%;
}
.contactInput {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 500px;
  color: var(--white);
}
.formLabelText {
  width: 90px;
  font-size: 1.2em;
  font-weight: 600;
  background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.supportFormSubmitButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 90px;
}
.supportFormError {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1em 1em 1em;
}
.supportFormHeader {
  margin-left: 90px;
  margin-bottom: 5px;
  background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: max-content;
}
.contactEmailError {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.supportFormSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1em 1em 1em;
}
@media (max-width: 750px) {
  .supportForm label {
    flex-direction: column;
    gap: 1em;
  }
  .formLabelText {
    width: 100%;
    justify-content: start;
  }
  .supportFormSubmitButton {
    margin-left: 0px;
  }
  .supportFormHeader {
    margin-left: 10px;
  }
  .contactUsChannels {
    padding: 1em;
  }
}
