.homePage {
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--gray-900);
}

.homepageVideoTutorial {
  width: 100%;
  max-width: 600px;
  margin-top: 1em;
  margin-bottom: 2em;
  padding: 5px;
  box-sizing: border-box;
}
.homepageVideoTutorial > * {
  width: 100%;
}
.homepageVideoTutorial > iframe {
  margin-top: 1em;
}
.homepageVideoTutorial > h3 {
  line-height: 1.5em;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.homePageFirstAnimatedText,
.homePageSecondAnimatedText {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.homePageFirstAnimatedText,
.homePageSecondAnimatedText > span {
  font-size: 1.6rem;
  font-weight: bold;
  background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.homePageFirstAnimatedText {
  animation: fadeInUp 0.6s ease-out forwards;
  margin-top: 1rem;
}

.homePageSecondAnimatedText {
  animation: fadeInUp 0.6s ease-out 0.6s forwards;
  margin-bottom: 1rem;
}

.homePageStartButton {
  padding: 4em 0 6em 0;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-size: 0.8em;
}

.animateTyping {
  text-align: left;
  box-sizing: border-box;
  line-height: 1.5em;
  animation: typing 1s steps(20, end) forwards;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.headline {
  text-align: left;
  width: 100%;
  max-width: 370px;
  padding: 0 1em;
  box-sizing: border-box;
}
.headline > span {
  color: var(--gray-300);
}
.headline > h2 {
  font-weight: bold;
  color: var(--gray-300);
  margin: 1em 0 0.5em 0;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1.8em;
}
.headline > h3 {
  font-weight: bold;
  margin: 0 0 1em 0;
  font-size: 1em;
  line-height: 1.5em;
}
.headline > h3 > span {
  color: var(--gray-300);
  font-style: italic;
  font-weight: normal;
}

.firstPhotoContainer {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.firstPhoto {
  width: 100%;
  height: 40vh;
  max-width: 350px;
  max-height: 440px;
  object-fit: contain;
  border-radius: 5px;
}

.secondFeaturesHeadline {
  width: 100%;
  font-size: 1.5em;
  padding: 3em 1em 0em 1em;
  box-sizing: border-box;
}
.secondFeaturesHeadline > h3 {
  margin: 0;
  line-height: 1.4em;
}
.secondFeaturesHeadline > p {
  font-size: 0.7em;
  line-height: 1.5em;
}

.tryOutText {
  margin: 1em 0 0 0;
  padding: 1em 1em 0.5em 1em;
  font-size: 1.2em;
  line-height: 1.5em;
  text-align: left;
  width: 90%;
}
.tryOutText > h3 {
  margin: 0;
  display: inline;
}

.selectTemplateText {
  margin-top: 2em;
  box-sizing: border-box;
  line-height: 1.5em;
  padding: 0 0.5em;
  text-align: center;
  margin-bottom: 0;
}
.demoViewContainer {
  width: 100%;
  overflow-x: auto;
  border-bottom: 2px solid rgb(255, 255, 255, 0.5);
  shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
}
.reviewArea {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
}
.reviewStars {
  display: flex;
}
.reviewText {
  font-size: 24px;
  font-weight: bold;
  color: var(--gray-900);
}

.reviewSecondRow,
.reviewFirstRow,
.reviewThirdRow {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-family: Roboto, Arial, sans-serif;
  flex: 1;
  line-height: 1.3em;
}

.reviewSecondRow {
  display: flex;
  font-size: 13px;
}

.infoRowFirstColumn,
.infoRowSecondColumn {
  flex: 1;
}
.infoRowFirstColumn {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: center;
  padding-left: 10px;
  line-height: 25px;
}
.infoRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-top: 1em;
}

.satisfactionRate {
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homePageInfoArea {
  width: 100%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reviewContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.rating {
  display: flex;
  gap: 5px;
  font-size: 24px;
  font-weight: bold;
}

.star {
  width: 30px;
  height: 30px;
  background: gray;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  transition: background 0.5s ease-in-out, transform 0.5s ease-in-out;
  animation: fillStar 0.5s ease-in-out forwards, pop 0.5s ease-in-out forwards;
  animation-delay: var(--delay);
}

.star.half::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: gray;
  top: 0;
  left: 0;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  transition: background 0.5s ease-in-out, transform 0.5s ease-in-out;
  animation: fillLastStar 0.5s ease-in-out forwards, pop 0.5s ease-in-out forwards;
  animation-delay: var(--delay);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes fillStar {
  from {
    background: gray;
  }
  to {
    background: #b57fca;
  }
}

@keyframes fillLastStar {
  from {
    background: gray;
  }
  to {
    background: linear-gradient(81deg, #b57fca, #9a73a9, gray);
  }
}

@keyframes pop {
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideInOut {
  0% {
    right: -60px;
    opacity: 0;
  }
  10% {
    opacity: 0.75;
  }
  50% {
    right: 10px;
  }
  90% {
    opacity: 0.75;
  }
  100% {
    right: 80px;
    opacity: 0;
  }
}

@media (min-width: 550px) {
  .firstPhoto {
    max-width: 80vw;
    max-height: 100%;
  }
  .firstScreen {
    width: 550px;
  }
  .infoRow {
    justify-content: space-around;
  }
  .infoRowFirstColumn {
    justify-content: center;
  }
}

@media (max-width: 549px) {
  .profileContainer {
    right: 0;
    bottom: 40px !important;
  }

  .discount {
    flex-direction: column;
    align-items: center;
  }
}
