.profileContainer {
  position: absolute;
  right: -75px;
  bottom: 60px;
  width: 200px;
  height: 114px;
  overflow: hidden;
}

.profileImg {
  position: absolute;
  right: -70px;
  width: 50px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 9px 5px var(--pink-color);
  animation: slideInOut 1.5s ease-in-out;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

@keyframes slideInOut {
  0% {
    right: -60px;
    opacity: 0;
  }
  10% {
    opacity: 0.75;
  }
  50% {
    right: 10px;
  }
  90% {
    opacity: 0.75;
  }
  100% {
    right: 80px;
    opacity: 0;
  }
}

@media (max-width: 549px) {
  .profileContainer {
    right: 0;
    bottom: 40px !important;
  }
} 