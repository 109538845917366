.templatesTitleFirst,
.templatesTitleSecond {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.templatesTitleFirst,
.templatesTitleSecond > span {
  font-size: 1.6rem;
  font-weight: bold;
  background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.templatesTitleFirst {
  animation: fadeInUp 0.6s ease-out forwards;
  margin-top: 1rem;
}

.templatesTitleSecond {
  animation: fadeInUp 0.6s ease-out 0.6s forwards;
  margin-bottom: 1rem;
}

.templateItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(var(--pink-rgb), 0.05);

  box-shadow: 0 0 8px 0 rgba(var(--pink-rgb), 0.5);
  padding: 1em;
  border-radius: 5px;
}

.templateItemTitleText {
  background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
