.boxContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 1em;
  padding: 1rem;
  box-shadow: inset 0 0 20px 0px white, inset 0 0 20px 20px white;
  background: linear-gradient(135deg, rgba(var(--danger-rgb), 0.5), rgba(var(--primary-rgb), 0.5));
}

.boxBody {
  background-color: var(--pink-color-light);
  border-radius: 5px;
  height: 100%;
  max-width: 100%;
  padding: 1.2rem;
  width: -webkit-fill-available;
}
