.editableDiv {
  outline-style: none;
  box-shadow: 0 0 5px 1px #a97fd4;
  border-radius: 0.315rem;
  white-space: pre-line;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  transition: box-shadow 0.3s ease-in-out;
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -moz-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
}

.error {
  box-shadow: 0 0 5px 1px red;
}
