.toggleSwitch {
  position: relative;
  width: 51px;
  height: 31px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.2s;
}

.toggleSwitch:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.toggleTrack {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 31px;
  transition: background-color 0.3s;
}

.toggleSwitch.enabled .toggleTrack {
  background-color: var(--premiumPurple-color);
}

.toggleThumb {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 27px;
  height: 27px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleSwitch.enabled .toggleThumb {
  transform: translateX(20px);
}

.toggleSwitch:focus {
  outline: none;
}

.toggleSwitch:focus-visible .toggleTrack {
  box-shadow: 0 0 0 2px var(--premiumPurple-color);
}
