.footer {
  width: 100%;
  height: wrap-content;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2em;
  font-size: 12px;
  color: var(--purple-color);
  border-image-slice: 1;
  border-top: 2px solid;
  box-sizing: border-box;
  border-image-source: linear-gradient(135deg, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5));
  margin-top: auto;
}

@media (min-width: 550px) {
  .footer {
    flex-direction: row;
  }
}
