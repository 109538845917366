.templateContainer {
  max-width: 750px;
}
.templateContainer.expiredBlur {
  filter: blur(5px);
}
/* #region first page */
.firstPageContainer {
  width: 100%;
  background: #ede9e2;
  padding: 3rem 5rem 3rem 5rem;
  color: #2d2c2c;
}

.divider {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 0.15rem solid black;
}

.dividerWithText {
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-family: none;
  font-weight: bold;
}

.dividerWithText::before,
.dividerWithText::after {
  flex: 1;
  content: "";
  padding: 0.05rem;
  background-color: var(--black);
  margin: 0.315rem;
}
.dividerWithText > span {
  padding-left: 0.5rem;
}

.doubleDivider {
  padding-top: 0.5rem;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 0.315rem double black;
}

.newspaperTitleSection {
  display: flex;
  padding-top: 0.315rem;
}
.newspaperTitleSection > .subtitle {
  position: absolute;
  margin-left: 1rem;
  text-align: center;
  font-size: 1rem;
  font-family: auto;
}
.newspaperTitleSection > .subtitle > p {
  margin: 0;
}
.newspaperTitleSection > .title {
  width: 100%;
  text-align: center;
  font-family: "Chomsky", sans-serif;
  font-size: 2rem;
  padding-top: 0.25rem;
  letter-spacing: 0.05rem;
}

.issueSection {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  text-align: center;
  font-style: italic;
  font-family: none;
  font-weight: bold;
  align-items: center;
}
.issueSection > .vol {
  width: 15%;
  text-align: left;
}
.issueSection > .releaseDate {
  width: 25%;
}
.issueSection > .city {
  width: 25%;
}

.captionSection {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 90%;
  text-align: center;
}

.captionSection > .caption {
  font-size: 3.15rem;
  font-family: none;
  letter-spacing: 0.315em;
  margin-top: 0.25rem;
  min-height: 7.25rem;
  overflow: hidden;
}
.captionSection > .subcaption {
  font-style: italic;
  font-family: none;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  min-height: 1.69rem;
  overflow: hidden;
}

.headlineImage {
  height: 35rem;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  padding-top: 0.5rem;
}
.headlineImage > div {
  padding: 0;
}
.headlineImage > img {
  max-width: none;
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 0.75rem;
  }
  .firstPageContainer {
    width: 70%;
  }
  .subtitle {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .firstPageContainer {
    width: unset;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    height: fit-content;
  }
  .captionSection > .caption {
    font-size: 2.25rem;
  }

  .issueSection {
    font-weight: 100;
  }

  .headlineImage {
    height: 20rem;
  }
}
/* #endregion */

/* #region second page  */
.secondPageContainer {
  height: fit-content;
  width: 100%;
  background: #ede9e2;
  padding: 3rem 5rem 3rem 5rem;
  color: #2d2c2c;
  transition: filter 0.3s ease-in-out;
}
.bucketListSection {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  text-align: center;
  font-style: italic;
  font-family: none;
  font-weight: bold;
  align-items: stretch;
}
.bucketListSection > .bucketListImageContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-around;
}
.bucketListSection > .bucketListImageContainer > .bucketListImage {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  padding-top: 0.5rem;
}

.bucketListSection > .bucketListImageContainer > .bucketListImage > img {
  max-width: none;
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
}
.bucketListSection > .bucketListContainer {
  display: flex;
  width: 21rem;
  margin-top: 0.5rem;
  border: 1px solid black;
  border-radius: 1.315rem;
  flex-direction: column;
}
.bucketListSection > .bucketListContainer > .bucketListTitle {
  display: flex;
  font-size: 2rem;
  font-style: normal;
  padding-top: 0.5rem;
  font-weight: 100;
  height: 2rem;
  justify-content: center;
  align-items: center;
}
.bucketListSection > .bucketListContainer > .bucketListDivider {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 0.15rem solid black;
  margin-right: 25%;
  margin-left: 25%;
  margin-top: 1rem;
}
.bucketListSection > .bucketListContainer > .bucketList {
  display: flex;
  height: 100%;
  padding-right: 2rem;
  padding-left: 4rem;
  padding-bottom: 1.5rem;
}
.bucketListSection > .bucketListContainer > .bucketList > ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.bucketListSection > .bucketListContainer > .bucketList > ul > li {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  text-align: start;
  font-family: none;
  font-style: normal;
  font-weight: 100;
  font-size: 0.9rem;
}
.bucketListSection > .bucketListContainer > .bucketList > ul > li > .iconDiv {
  min-width: 0.75rem;
  padding-right: 0.5rem;
}
.favoriteMemorySection {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-self: center;
  text-align: center;
  justify-content: space-between;
  height: fit-content;
}
.favoriteMemorySection > .favoriteMemoryTextSection {
  width: 52%;
  height: fit-content;
}
.favoriteMemorySection > .favoriteMemoryTextSection > .favoriteMemoryTitle {
  font-weight: bold;
  font-family: none;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  min-height: 1.69rem;
  max-height: 1.69rem;
  overflow: hidden;
}
.favoriteMemorySection > .favoriteMemoryTextSection > .favoriteMemoryText {
  text-align: start;
}
.favoriteMemorySection > .favoriteMemoryImageSection {
  width: 42%;
}
.favoriteMemorySection > .favoriteMemoryImageSection > .favoriteMemoryImage {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  padding-top: 0.5rem;
  height: 100%;
}
.favoriteMemorySection > .favoriteMemoryImageSection > .favoriteMemoryImage > img {
  max-width: none;
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
}

@media only screen and (max-width: 768px) {
  .secondPageContainer {
    width: 70%;
  }
}

@media only screen and (max-width: 576px) {
  .secondPageContainer {
    width: unset;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  }

  .bucketListSection {
    flex-direction: column;
  }
  .bucketListSection > .bucketListContainer {
    width: 100%;
  }
  .bucketListSection > .bucketListImageContainer {
    width: unset;
  }
  .favoriteMemorySection {
    flex-direction: column;
    height: unset;
  }
  .favoriteMemorySection > .favoriteMemoryTextSection {
    width: unset;
    height: fit-content;
  }
  .favoriteMemorySection > .favoriteMemoryImageSection {
    width: unset;
  }
}
/* #endregion  */

/* #region third page */
.thirdPageContainer {
  height: fit-content;
  width: 100%;
  background: #ede9e2;
  padding: 3rem 5rem 3rem 5rem;
  color: #2d2c2c;
  transition: filter 0.3s ease-in-out;
}

.disableButton {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  left: 100%;
  color: white;
  background: gray;
  text-align: center;
  border-radius: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  transform: translateY(150%);
  z-index: 4;
  user-select: none;
}
.disableButton:hover {
  cursor: pointer;
}
.disableButton > button {
  background: transparent;
  color: white;
  border: none;
}
.disableButton > button:hover {
  cursor: pointer;
}

.thirdPageHeadlineImage {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.thirdPageHeadlineImage > img {
  max-width: none;
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
}

.favoriteThingsSection {
  display: flex;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 1rem;
  margin-top: 0.5rem;
  justify-content: space-between;
}
.favoriteThingsSection > .favoriteThingsListSection {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.favoriteThingsSection > .favoriteThingsListSection > .favoriteThingsTitle {
  font-size: x-large;
  font-family: none;
  font-weight: bold;
  text-align: center;
  padding-top: 1rem;
}
.favoriteThingsSection > .favoriteThingsListSection > .favoriteThingsList {
  display: flex;
  height: 100%;
  padding-bottom: 1.5rem;
}
.favoriteThingsSection > .favoriteThingsListSection > .favoriteThingsList > ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.favoriteThingsSection > .favoriteThingsListSection > .favoriteThingsList > ul > li {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  text-align: start;
  font-family: none;
  font-style: normal;
  font-weight: 100;
  font-size: 0.9rem;
}
.favoriteThingsSection > .favoriteThingsListSection > .favoriteThingsList > ul > li > .iconDiv {
  min-width: 1.5rem;
  min-height: 1.5rem;
  padding-right: 1rem;
}
.favoriteThingsSection > .favoriteThingsListSection > .favoriteThingsList > .favoriteThingsListEditable {
  min-height: 10rem;
}
.favoriteThingsSection > .favoriteThingsImage {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  width: 50%;
}
.favoriteThingsSection > .favoriteThingsImage > img {
  max-width: none;
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
}

.funFactSection {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: space-around;
  align-items: center;
}
.funFactSection > .factsText {
  font-size: 1.5rem;
  width: 15%;
  text-align: center;
  font-family: none;
  font-weight: bold;
}
.funFactSection > .funFacts {
  width: 25%;
  height: 3.5rem;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: none;
  font-style: italic;
  font-size: 1.1rem;
}

@media only screen and (max-width: 576px) {
  .thirdPageContainer {
    width: unset;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  }
  .funFactSection {
    flex-direction: column;
  }
  .funFactSection > .factsText {
    width: 100%;
  }
  .funFactSection > .funFacts {
    width: 100%;
  }
  .favoriteThingsSection {
    flex-direction: column;
  }
  .favoriteThingsSection > .favoriteThingsImage,
  .favoriteThingsSection > .favoriteThingsListSection {
    width: unset;
  }
}
/* #endregion */

.disabledPage {
  filter: opacity(0.2);
  user-select: none;
}
@media only screen and (max-width: 1200px) {
  html {
    font-size: 0.95rem;
  }
}
@media only screen and (max-width: 991px) {
  html {
    font-size: 0.9rem;
  }

  .captionSection > .caption {
    letter-spacing: 0.15rem;
  }
}

@media only screen and (max-width: 576px) {
  .disableButton {
    left: 65%;
    transform: translateY(100%);
  }
}
